<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            Orden Clinica - Servicios / Procedimientos
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                        Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2" v-for="item in orden.items" :key="item.id">
                <div class="card-body" >
                    <h6>Servicios / Procedimiento: <span class="text-primary">{{item.servicio.descripcion}}</span></h6>
                    <h6>Cantidad: <span class="badge badge-secondary badge-marketing">{{item.cantidad}}</span></h6>
                    <h6>Observaciones: {{item.observacion}}</h6>
                </div>
            </div>
            <div class="form-group mt-2 text-center">
                <button @click="imprimir(orden.id)" class="btn btn-primary lift" >
                    <i class="fa fa-print fa-fw"></i> Imprimir
                </button>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from '../../../../services/usuarioService';
import ordenClinicaService from '../../../../services/ordenClinicaService';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from "../../../../services/historiaClinicaService";

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact},
    data(){
        return {
            usuario: {},
            orden:{}
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarOrden(id){
            const response = await ordenClinicaService.showOrdenById(id);
            this.orden= response.data;
        },
        imprimir(id_orden){
            window.open(historiaClinicaService.orden(id_orden));
        }
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            await this.cargarUsuario(this.$route.params.id_usuario);
            await this.cargarOrden(this.$route.params.id_orden);
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
        
    }
}
</script>